<template>
  <div class="account-box">
    <!-- <div class="toggle">
      <a @click="isMsgLogin=false" href="javascript:;" v-if="isMsgLogin">
        <i class="iconfont icon-user"></i> 账号
      </a>
      <a @click="isMsgLogin=true" href="javascript:;" v-else>
        <i class="iconfont icon-msg"></i> 使用短信登录
      </a>
    </div> -->
    <form ref="formCom" class="form">
      <div class="form-item">
        <div class="input">
          <i class="iconfont icon-user"></i>
          <!-- <Field :class="{error:errors.mobile}" v-model="form.mobile" name="mobile" type="text" placeholder="请输入手机号" /> -->
          <input type="text" :class="{error:errors.email}" @blur="check('email')" v-model="form.email" placeholder="请输入您的邮箱" />
        </div>
        <div class="error" v-if="errors.email"><i class="iconfont icon-warning" />{{errors.email}}</div>
      </div>
      <div class="form-item">
        <div class="input">
          <i class="iconfont icon-password"></i>
          <input type="password" :class="{error:errors.password}" @blur="check('password')" v-model="form.password" placeholder="请输入密码">
          <!-- <Field :class="{error:errors.password}" v-model="form.password" name="password" type="password" placeholder="请输入密码" /> -->
        </div>
        <div class="error" v-if="errors.password"><i class="iconfont icon-warning" />{{errors.password}}</div>
      </div>
      <PButton class="btn" size="loginSize" @click="loginClick" :hoverActive='false' :active='btnActive'>{{btnActive==true?'登录':'登录中...'}}</PButton>
    </form>
  </div>
</template>
<script>
// //import { Form, Field } from 'vee-validate'
import schema from '@/utils/schema'
//import {login} from '../../../api/login'
import {ref,reactive } from 'vue'
import Message from '../../../components/library/Message'
import { useRouter } from 'vue-router'
//import {useStore} from 'vuex'
import axios from '../../../utils/request'
import qs from "qs";
//import {queryCollectByUserId} from '../../../api/collect'
// //import { userAccountLogin,userMobileLogin,userMobileLoginMsg } from '@/api/user'
// //import Message from '@/components/library/Message'
// import { useStore } from 'vuex'
// import { useRoute, useRouter } from 'vue-router'
// import { useIntervalFn } from '@vueuse/core'
export default {
  name: 'LoginForm',
  components:{
    // Form, 
    // Field
  },
  setup () {
    // // 控制短信登录切换的
    // const isMsgLogin = ref(false)
    // // 表单对象数据
    const form = reactive({
      email:null,
      password: null,
    })
    //校验规则对象
    const mySchema = {
      email: schema.email,
      password: schema.password,
    }

    const errors=reactive({
      email:null,
      password:null
    })

    const check=(p)=>{
      let re=mySchema[p](form[p]);
      if(re==true){
        errors[p]=null;
        return true;
      }else{
        errors[p]=re;
        return false;
      }
    }

    const checkAll=()=>{
      let bool=true;
      Object.keys(form).map(key=>{
        let re=check(key);
        if(re==false){
          bool=false;
        }
      })
      return bool;
    }

    let btnActive=ref(true);
    //const store=useStore();
    // 使用router
    const router = useRouter()
    const loginClick =() => {
      if(btnActive.value==false){
        return;
      }
      
      // 整体校验
      const valid = checkAll();
      console.log(valid);
      
      if (valid) {
        btnActive.value=false;
        axios.post("/user/login",qs.stringify({email:form.email,password:form.password})).then((res)=>{
          Message({type:'success',text:'登录成功'}).then(()=>{
            localStorage.setItem("user",JSON.stringify(res));
            router.push('/');
          });
        }).catch((err)=>{
          Message({type:"error",text:err.message});
        }).finally(()=>{
          btnActive.value=true;
        })
      // 发送请求
      //   login(form.email,form.password).then((res)=>{
      //     console.log(res);
      //     if(res.data.status==200){
      //       Message({type:'success',text:'登录成功'}).then(()=>{
      //         console.log(res);
      //         console.log(store);
      //         store.commit('user/setLoginUser',res.data.data);
      //         queryCollectByUserId(res.data.data.id).then((resp)=>{
      //           console.log(resp);
      //           store.commit('collect/setCollects',resp.data);
      //         })
      //         router.push('/');
      //       });
      //     }else if(res.data.status==202){
      //       Message({type:'error',text:'密码错误'});
      //       btnActive.value=true;
      //     }else if(res.data.status==201){
      //       Message({type:'warn',text:'账号未注册'});
      //       btnActive.value=true;
      //     }else{
      //       Message({type:'error',text:'未知错误'});
      //       btnActive.value=true;
      //     }
      //   }).catch(function(){
      //     Message({type:'error',text:'服务器启动中，请稍后再试'});
      //     btnActive.value=true;
      //   })
      // }
      }
    }

    // // 切换表单元素，还原数据和清除校验效果
    // const formCom = ref(null)
    // watch(isMsgLogin, () => {
    //   // 还原数据
    //   form.isAgree = true
    //   form.account = null
    //   form.password = null
    //   form.mobile = null
    //   form.code = null
    //   // 补充校验效果清除，Form组件提供resetForm()
    //   formCom.value.resetForm()
    // })

    // // 需要在点击登录的时候对整体表单进行校验
    // // 使用store
    // const store = useStore()
    // // 使用router
    // const router = useRouter()
    // // 使用route
    // const route = useRoute()
    // // 登录提交
    // const login = async () => {
    //   // 整体校验
    //   const valid = await formCom.value.validate()
    //   if (valid) {
    //     // 发送请求
    //     let data = null
    //     try {
    //       if (!isMsgLogin.value) {
    //         // 帐号登录
    //         data = await userAccountLogin(form)
    //       } else {
    //         // 短信登录
    //         // 1. 定义两个API  短信登录，获取短信验证码
    //         // 2. 实现发送短信验证码发送功能
    //         // 3. 完成手机号短信验证码登录逻辑
    //         data = await userMobileLogin(form)
    //       }
    //     } catch (e) {
    //       Message({ type: 'error', text: e.response.data.message || '登录失败' })
    //     }
    //     // 成功
    //     // 1. 存储信息
    //     const { id, account, nickname, avatar, token, mobile } = data.result
    //     store.commit('user/setUser', { id, account, nickname, avatar, token, mobile })
    //     // 2. 提示
    //     Message({ type: 'success', text: '登录成功' })
    //     // 3. 跳转
    //     router.push(route.query.redirectUrl || '/')
    //   }
    // }

    // // pause 暂停 resume 开始
    // // useIntervalFn(回调函数,执行间隔,是否立即开启)
    // const time = ref(0)
    // const { pause, resume } = useIntervalFn(() => {
    //   time.value--
    //   if (time.value <= 0) {
    //     pause()
    //   }
    // }, 1000, false)
    // onUnmounted(() => {
    //   pause()
    // })

    // // 发送短信
    // const send = async () => {
    //   const valid = mySchema.mobile(form.mobile)
    //   if (valid === true) {
    //     // 通过
    //     if (time.value === 0) {
    //       console.log("jrsl")
    //     // 没有倒计时才可以发送
    //       await userMobileLoginMsg(form.mobile)
    //       console.log("jl")
    //       Message({ type: 'success', text: '发送成功' })
    //       time.value = 60
    //       resume()
    //     }
    //   } else {
    //     console.log(valid)
    //     // 失败，使用vee的错误函数显示错误信息 setFieldError(字段,错误信息)
    //     formCom.value.setFieldError('mobile', valid)
    //   }
    // }
    
    //return {time,send, isMsgLogin, form, schema: mySchema, formCom, login }
    
    return {form,errors,check,loginClick,btnActive};
 } 
}
</script>


<style lang="less" scoped>
// 账号容器
.account-box {
  .toggle {
    padding: 15px 40px;
    text-align: right;
    a {
      color: @xtxColor;
      i {
        font-size: 14px;
      }
    }
  }
  .form {
    margin-top: 50px;
    padding: 0 40px;
    &-item {
      margin-bottom: 28px;
      .input {
        position: relative;
        height: 36px;
        > i {
          margin: 1px;
          width: 32px;
          height: 32px;
          background: #cfcdcd;
          color: #fff;
          position: absolute;
          left: 1px;
          top: 1px;
          text-align: center;
          line-height: 34px;
          font-size: 18px;
        }

        input {
          padding-left: 44px;
          border: 1px solid #cfcdcd;
          height: 36px;
          line-height: 36px;
          width: 100%;
          &.error {
            border-color: @priceColor;
          }
          //&.active,
          &:focus {
            border-color: black;
          }
        }
        &:focus-within{
          i{
            color: black;
          }
        }
        .code {
          position: absolute;
          right: 1px;
          top: 1px;
          text-align: center;
          line-height: 34px;
          font-size: 14px;
          background: #f5f5f5;
          color: #666;
          width: 90px;
          height: 34px;
          cursor: pointer;
        }
      }
      > .error {
        position: absolute;
        font-size: 12px;
        line-height: 28px;
        color: @priceColor;
        i {
          font-size: 14px;
          margin-right: 2px;
        }
      }
    }
    .agree {
      a {
        color: #069;
      }
    }
    // .btn {
    //   //margin-left: 20px;
    // }
  }
  .action {
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .url {
      a {
        color: #999;
        margin-left: 10px;
      }
    }
  }
}
</style>