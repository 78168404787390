<template>
<div>
  <LoginHeader>欢迎注册登录</LoginHeader>
  <vue-particles
      color="#dedede"
      :particleOpacity="0.9"
      :particlesNumber="85"
      shapeType="circle"
      :particleSize="4"
      linesColor="#dedede"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.6"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
  </vue-particles>

    
    <section class="login-section">
      <div class="wrapper">
        <!-- <div class="content"> -->
          <nav>
            <a @click="activeName='login'" :class="{active:activeName==='login'}" href="javascript:;">账户登录</a>
            <a @click="activeName='register'" :class="{active:activeName==='register'}" href="javascript:;">注册账户</a>
          </nav>
          <!-- 登录 -->
          <LoginBody v-if="activeName==='login'"></LoginBody>
          <!-- 注册 -->
          <RegisterForm v-if="activeName==='register'"></RegisterForm>
        <!-- </div> -->
      </div>
    </section>
    <LoginFooter/>
  
    <!-- <div class="particles"> -->
      
</div>

</template>

<script>
import LoginHeader from './components/login-header'
import LoginFooter from './components/login-footer'
import { ref } from 'vue'
import RegisterForm from './components/register-form.vue'
import LoginBody from './components/login-body.vue'

export default {
  name: 'PageLogin',
  components: { LoginHeader, LoginFooter,LoginBody,RegisterForm},
  setup () {
    const activeName = ref('login');
    return { activeName }
  }
}
</script>

<style scoped lang='less'>
// .head{
//   position:absolute;
//   z-index: 999;
// }
// .login-section {
//   //background: url(../../assets/images/loginb.png) no-repeat center / cover;
//   height: 550px;
//   position: relative;
  .wrapper {
    width: 380px;
    background: #fff;
    min-height: 400px;
    position: absolute;
    left: 50%;
    top: 154px;
    transform: translate3d(100px,0,0);
    box-shadow: 0 0 10px rgba(0,0,0,.15);
    z-index: 999;
    nav {
      height: 55px;
      border-bottom: 1px solid #f5f5f5;
      display: flex;
      padding: 0 40px;
      text-align: right;
      align-items: center;  
      a {
        flex: 1;
        line-height: 1;
        display: inline-block;
        font-size: 18px;
        position: relative;
        &:first-child {
          border-right: 1px solid #f5f5f5;
          text-align: left;
        }
        &.active{
          //color: @xtxColor;
          font-weight: bold;
        }
      }
    }
  }
// }

// 二维码容器
.qrcode-box {
  text-align: center;
  padding-top: 40px;
  p {
    margin-top: 20px;
    a {
      //color: @xtxColor;
      font-size: 16px;
    }
  }
}
// .content{
//   position: absolute;
//   z-index: 999;
//   //pointer-events: inherit;
// }
#particles-js{
  background: url(../../assets/images/loginb.png) no-repeat center / cover;
  width: 100%;
  height: calc(100%);
  // position: absolute;
  // left: 0;
  // top: 100px;
  // z-index: 99;
  //pointer-events: auto;
}
// .particles{
//   width: 100%;
//   height: 100%;
//   position:absolute;
//   left: 0;
//   top: 0;
// }
</style>