<template>
<div class="footer">
    <div class="content">
        <div class="wtfk">
            <div class="tb">
                <span class="iconfont icon-wentifankui"></span>
                <h3>问题反馈</h3>
            </div>
            <img src="../../assets/images/wx.jpg"/>
        </div>
        <div class="wxts">
            <div class="tb">
                <span class="iconfont icon-wenxintishi"></span>
                <h3>温馨提示</h3>  
            </div>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本网页仅供娱乐，部分图片乐谱来源于网络，如有侵权请联系作者删除。</p>
        </div>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.footer{
    width: 100%;
    height: 150px;
    margin-top:50px;
    background-color: white;
    .content{
        width: 1200px;
        //border: solid;
        margin: auto;
        .wtfk{
            float: left;
            //border: solid;
            width: 400px;
            height: 150px;
            .tb{
                float: left;
                width: 80px;
                height: 120px;
                margin-left: 50px;
                margin-top:10px;
                .iconfont{
                    font-size: 70px;
                    //display: block;
                    //border: solid;
                }
                h3{
                    text-align: center;
                    line-height: 5px;
                }
                
            }
            img{
                float: left;
                margin-top: 30px;
                margin-left: 20px;
                //border: solid;
                width: 80px;
                height: 80px;
            }
        }
        .wxts{
            //border: solid;
            width: 500px;
            height: 150px;
            float: left;
            .tb{
                float: left;
                width: 80px;
                height: 120px;
                margin-left: 50px;
                margin-top:10px;
                .iconfont{
                    font-size: 70px;
                    //display: block;
                    //border: solid;
                }
                h3{
                    text-align: center;
                    line-height: 5px;
                }
                
            }
            p{
                float: left;
                width: 200px;
                margin-top:40px;
                margin-left:20px;
                //border: solid;
                font-size: 15px;
                font-weight: 700;
            }
        }
    }
}
</style>