<template>
  <footer class="login-footer">
    <Footer></Footer>
  </footer>
</template>

<script>
import Footer from '../../components/footer.vue'
export default {
  name: 'LoginFooter',
  components:{Footer}
}
</script>

<style scoped lang='less'>
// .login-footer {
//   padding: 30px 0 50px;
//   background: #fff;
//   p {
//     text-align: center;
//     color: #999;
//     padding-top: 20px;
//     a {
//       line-height: 1;
//       padding:0 10px;
//       color: #999;
//       display: inline-block;
//       ~ a {
//         border-left: 1px solid #ccc;
//       }
//     }
//   }
// }
</style>