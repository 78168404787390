<template>
  <div>
    <template v-if="forget">
        <ForgetForm/>
        <div class="fbt" @click="forget=!forget">账户登录</div>
    </template>
    <template v-else>
        <LoginForm/>
        <div class="fbt" @click="forget=!forget">忘记密码</div>
    </template>
  </div>
</template>

<script>
import {ref} from 'vue';
import ForgetForm from './forget-form.vue'
import LoginForm from './login-form.vue'
export default {
    name:'LoginBody',
    components:{
        ForgetForm,
        LoginForm
    },
    setup(){
        const forget=ref(false);
        return {forget};
    }
}
</script>

<style lang="less" scoped>
.fbt{
    margin-left: 40px;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #999;
    &:hover{
        cursor: pointer;
    }
}
</style>