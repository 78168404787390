<template>
  <header class="login-header">
    <div class="container">
      <h1 class="logo">
        <RouterLink to="/">
          <div class="log"><img src="../../../assets/images/logo.png"/></div>
          <div class="title">
            <div class="t1">网页钢琴</div>
            <div class="t2">author:两行</div>
          </div>
        </RouterLink>
      </h1>
      <h3 class="sub"><slot /></h3>
      <RouterLink class="entry" to="/">
        进入网站首页
        <i class="iconfont icon-right"></i>
        <i class="iconfont icon-right"></i>
      </RouterLink>
    </div>
  </header>
</template>

<script>
export default {
  name: 'LoginHeader'
}
</script>

<style scoped lang='less'>
.login-header {
  background: #fff;
  height: 110px;
  .container {
    height: 110px;
    display: flex;
    //align-items: center;
    .user{
      //border: solid;
      height: 50px;
      width: 150px;
      margin-top: 30px;
      img{
        height: 50px;
        width: 50px;
        border-radius: 25px;
        float: left;
      }
      div{
        float: left;
        margin-left: 20px;
        font-size: 15px;
        font-weight: 800;
        line-height: 50px;
      }
    }
    .sub {
      flex: 1;
      font-size: 24px;
      font-weight: normal;
      margin-top: 50px;
      margin-left: 20px;
      color: #666;
    }
    .entry {
      width: 120px;
      margin-top: 50px;
      font-size: 16px;
      i {
        font-size: 14px;
        //color: @xtxColor;
        letter-spacing: -5px;
      }
    }
  }
  .logo {
    width: 200px;
    line-height: 100px;
   
    //border: solid;
    img {
      display:block;
      //border: solid;
      height: 70px;
      float: left;
      margin-top: 20px;
      margin-left: 10px;
      -webkit-user-drag: none;
      //width: 100%;
      // text-indent: -9999px;
    }
   
    
    .title{
      text-align: center;
      margin-left: 20px;
      margin-top:20px;
      float: left;
      .t1{
        line-height: 50px;
        font-size: 25px;
      }
      .t2{
        line-height: 10px;
        font-size: 10px;
      }
    }
  }
  .navs {
    margin-top:30px;
    width: 800px;
    //border: solid;
    display: flex;
    margin-left: 20px;
    //justify-content: space-around;
    //padding-left: 20px;
    li{
      margin-right: 10px;
    }
  }
}
// .login-header {
//   background: #fff;
//   border-bottom: 1px solid #e4e4e4;
//   .container {
//     display: flex;
//     align-items: flex-end;
//     justify-content: space-between;
//   }
//   .logo {
//     width: 200px;
//     a {
//       display: block;
//       height: 132px;
//       width: 100%;
//       text-indent: -9999px;
//       background: url(../../../assets/images/logo.png) no-repeat center 18px / contain;
//     }
//   }
//   .sub {
//     flex: 1;
//     font-size: 24px;
//     font-weight: normal;
//     margin-bottom: 38px;
//     margin-left: 20px;
//     color: #666;
//   }
//   .entry {
//     width: 120px;
//     margin-bottom: 38px;
//     font-size: 16px;
//     i {
//       font-size: 14px;
//       color: @xtxColor;
//       letter-spacing: -5px;
//     }
//   }
// }
</style>