<template>
  <div class="account-box">
    <!-- <div class="toggle">
      <a @click="isMsgLogin=false" href="javascript:;" v-if="isMsgLogin">
        <i class="iconfont icon-user"></i> 账号
      </a>
      <a @click="isMsgLogin=true" href="javascript:;" v-else>
        <i class="iconfont icon-msg"></i> 使用短信登录
      </a>
    </div> -->
    <form ref="formCom" class="form">
      
        <div class="form-item">
          <div class="input">
            <i class="iconfont icon-user"></i>
            <!-- <Field :class="{error:errors.mobile}" v-model="form.mobile" name="mobile" type="text" placeholder="请输入手机号" /> -->
            <input type="text" :class="{error:errors.email}" @blur="check('email')" v-model="form.email" placeholder="请输入您的邮箱" />
          </div>
          <div class="error" v-if="errors.email"><i class="iconfont icon-warn" />{{errors.email}}</div>
        </div>
        <div class="form-item">
          <div class="input">
            <i class="iconfont icon-password"></i>
            <input type="password" :class="{error:errors.password}" @blur="check('password')" v-model="form.password" placeholder="请输入密码">
            <!-- <Field :class="{error:errors.password}" v-model="form.password" name="password" type="password" placeholder="请输入密码" /> -->
          </div>
          <div class="error" v-if="errors.password"><i class="iconfont icon-warn" />{{errors.password}}</div>
        </div>
        <div class="form-item">
          <div class="input">
            <i class="iconfont icon-password"></i>
            <input type="password" :class="{error:errors.rPassword}" @blur="check('rPassword')" v-model="form.rPassword" placeholder="请再次输入密码">
            <!-- <Field :class="{error:errors.password}" v-model="form.password" name="password" type="password" placeholder="请输入密码" /> -->
          </div>
          <div class="error" v-if="errors.rPassword"><i class="iconfont icon-warn" />{{errors.rPassword}}</div>
        </div>
        <div class="form-item">
          <div class="input">
            <i class="iconfont icon-code"></i>
            <!-- <Field :class="{error:errors.code}" v-model="form.code" name="code" type="password" placeholder="请输入验证码" /> -->
            <input type="text" :class="{error:errors.code}" @blur="check('code')" v-model="form.code" placeholder="请输入验证码">
            <span @click="sendEmail" class="code">
              {{time===0?'获取验证码':`${time}秒后发送`}}
            </span>
          </div>
          <div class="error" v-if="errors.code"><i class="iconfont icon-warn" />{{errors.code}}</div>
        </div>
      
      <!-- <div class="form-item">
        <div class="agree">
          <span>我已同意</span>
          <a href="javascript:;">《隐私条款》</a>
          <span>和</span>
          <a href="javascript:;">《服务条款》</a>
        </div>
      </div> -->
      <PButton class="btn" @click="registerClick" size="loginSize" :hoverActive='false' :active='btnActive'>注册</PButton>
    </form>
    <!-- <div class="action">
      <img src="https://qzonestyle.gtimg.cn/qzone/vas/opensns/res/img/Connect_logo_7.png" alt="">
      <div class="url">
        <a href="javascript:;">忘记密码</a>
      </div>
    </div> -->
  </div>
  
</template>
<script>
// //import { Form, Field } from 'vee-validate'
// //import schema from '@/utils/vee-validate-schema'
import schema from '@/utils/schema'
// import { reactive, ref ,watch,onUnmounted} from 'vue'
import { ref,reactive,onUnmounted } from 'vue'
// import {getCode,register} from '../../../api/register';
// //import { userAccountLogin,userMobileLogin,userMobileLoginMsg } from '@/api/user'
// //import Message from '@/components/library/Message'
// import { useStore } from 'vuex'
// import { useRoute, useRouter } from 'vue-router'
import { useIntervalFn } from '@vueuse/core'
import Message from '../../../components/library/Message'
import axios from "../../../utils/request";
import qs from "qs";
//import { useRouter } from 'vue-router'
export default {
  name: 'LoginForm',
  components:{
    // Form, 
    // Field
  },
  setup () {
    // // 控制短信登录切换的
    // const isMsgLogin = ref(false)
    // 表单对象数据
    const form = reactive({
      //isAgree: true,
      email: null,
      password: null,
      rPassword:null,
      code: null
    })
    // 校验规则对象
    const mySchema = {
      email: schema.email,
      password: schema.password,
      rPassword: schema.password,
      code: schema.code,
    }

    const errors=reactive({
      email:null,
      password:null,
      rPassword:null,
      code:null
    })

    const check=(p)=>{
      let re=mySchema[p](form[p]);
      if(re==true){
        errors[p]=null;
        return true;
      }else{
        errors[p]=re;
        return false;
      }
    }

    const checkAll=()=>{
      let bool=true;
      Object.keys(form).map(key=>{
        let re=check(key);
        if(re==false){
          bool=false;
        }
      })
      return bool;
    }

    let btnActive=ref(true);
    //const router = useRouter()
    // 注册提交
    const registerClick =() => {
      if(btnActive.value==false){
        return;
      }
      if(form.password!=form.rPassword){
        Message({type:'error',text:'两次密码输入不一致'});
        return;
      }
      // 整体校验
      const valid = checkAll();
      //console.log(valid);
      if (valid) {
        btnActive.value=false;
        axios.post("/user/register",qs.stringify({email:form.email,password:form.password,code:form.code})).then(()=>{
            Message({type:'success',text:'注册成功！'}).then(()=>{
              location.reload();
            })
        }).catch((err)=>{
          Message({type:"error",text:err.message});
        }).finally(()=>{
          btnActive.value=true;
        })
        // // 发送请求
        // register(form.email,form.password,form.code).then((res)=>{
        //   console.log(res);
        //   if(res.data=="success"){
        //     Message({type:'success',text:'注册成功！'}).then(()=>{
        //       location.reload();
        //     })
        //   }else if(res.data=="codeErr"){
        //     Message({type:'error',text:'验证码错误'})
        //   }else if(res.data=="repeat"){
        //     Message({type:'warn',text:'此账号已注册，请勿重复注册！'})
        //   }else{
        //     Message({type:'error',text:'未知错误'})
        //   }
        //   btnActive.value=true;
        // }).catch(function(err){
        //   Message({type:'error',text:err});
        //   btnActive.value=true;
        // })
      }
    }

    // pause 暂停 resume 开始
    // useIntervalFn(回调函数,执行间隔,是否立即开启)
    const time = ref(0);
    const { pause, resume } = useIntervalFn(() => {
      time.value--
      if (time.value <= 0) {
        pause()
      }
    }, 1000, false)
    onUnmounted(() => {
      pause()
    })

    // 发送邮件
    const sendEmail =() => {
      const valid = mySchema.email(form.email);
      if (valid === true) {
        // 通过
        if (time.value === 0) {
          // 没有倒计时才可以发送
          //console.log(form.email);
          //getCode(form.email);
          axios.post("/user/getCode",qs.stringify({email:form.email,text:"您正在注册网页钢琴账号"})).then(()=>{
            Message({ type: 'success', text: '验证码已发送至您的邮箱' });
          }).catch(()=>{
            Message({type:"error",text:"发送失败，请稍后再试"});
          })
          //Message({ type: 'success', text: '发送成功' })
          time.value = 60
          resume()
        }
      } else {
        //console.log(valid)
        errors.email=valid;
        // 失败，使用vee的错误函数显示错误信息 setFieldError(字段,错误信息)
        //formCom.value.setFieldError('mobile', valid)
      }
    }
    
    //return {time,send, isMsgLogin, form, schema: mySchema, formCom, login }
    return {time,form,errors,check,sendEmail,registerClick,btnActive};
 } 
}
</script>


<style lang="less" scoped>
// 账号容器
.account-box {
  .toggle {
    padding: 15px 40px;
    text-align: right;
    a {
      color: @xtxColor;
      i {
        font-size: 14px;
      }
    }
  }
  .form {
    margin-top: 50px;
    padding: 0 40px;
    &-item {
      margin-bottom: 28px;
      .input {
        position: relative;
        height: 36px;
        > i {
          margin: 1px;
          width: 32px;
          height: 32px;
          background: #cfcdcd;
          color: #fff;
          position: absolute;
          left: 1px;
          top: 1px;
          text-align: center;
          line-height: 34px;
          font-size: 18px;
        }

        input {
          padding-left: 44px;
          border: 1px solid #cfcdcd;
          height: 36px;
          line-height: 36px;
          width: 100%;
          &.error {
            border-color: @priceColor;
          }
          //&.active,
          &:focus {
            border-color: black;
          }
        }
        &:focus-within{
          i{
            color: black;
          }
        }
        .code {
          position: absolute;
          right: 1px;
          top: 1px;
          margin: 1px;
          text-align: center;
          line-height: 34px;
          font-size: 14px;
          background: #f5f5f5;
          color: #666;
          width: 90px;
          height: 33px;
          cursor: pointer;
        }
      }
      > .error {
        position: absolute;
        font-size: 12px;
        line-height: 28px;
        color: @priceColor;
        i {
          font-size: 14px;
          margin-right: 2px;
        }
      }
    }
    .agree {
      a {
        color: #069;
      }
    }
    .btn {
      margin-bottom: 30px;
      //margin-left: 20px;
    }
  }
  // .action {
  //   padding: 20px 40px;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   .url {
  //     a {
  //       color: #999;
  //       margin-left: 10px;
  //     }
  //   }
  // }
}
</style>